import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SnackBarComponent } from '@shared/components/snackbar/snackbar.component';
import { ProgressSpinnerComponent } from '@shared/components/progress-spinner/progress-spinner.component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IConfirmDialogConfig } from '@shared/models';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  handle: any;
  handleAll: any;
  private dialogsOpened = 0;
  private isDialogOpened: boolean;
  private dialogRef: any;

  constructor(private deviceService: DeviceDetectorService, private _snackBar: MatSnackBar,
              private dialog: MatDialog, private titleService: Title) {
    this.isDialogOpened = false;
  }

  showProgressDialog(): any {
    this.dialogsOpened += 1;
    if (!this.isDialogOpened) {
      this.isDialogOpened = true;
      this.dialogRef = this.dialog.open(ProgressSpinnerComponent, {
        disableClose: true,
        panelClass: 'progress-spinner'
      });
    }
  }

  closeProgressDialog(): any {
    this.dialogsOpened = this.dialogsOpened >= 1 ? this.dialogsOpened - 1 : 0;
    if (this.isDialogOpened && this.dialogsOpened === 0) {
      this.isDialogOpened = false;
      this.dialogRef.close();
    }
  }

  getAPIServerUrl(): string {
    return environment.api_url;
  }

  saveAuthToken(token: string, refresh?: string) {
    localStorage.setItem('token', token);
    refresh && localStorage.setItem('refresh', refresh);
  }

  /**
   * check if the token is valid and not expired
   * @param token
   */
  isValidToken(token: string | null) {
    if (token) {
      try {
        const decode = this.decodeToken(token);
        if (Date.now() < decode.exp * 1000) {
          return true;
        }
      } catch {
        return false;
      }
    }
    return false;
  }

  clearAuth() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('userId');
  }

  mainWebsite(): string {
    return environment.main_websitelink;
  }

  protocol(): string {
    return environment.protocol;
  }

  pageSize(): number {
    return environment.page_size;
  }

  isMobile(): any {
    if (this.isTablet()) {
      return true
    }
    return this.deviceService.isMobile();
  }

  isDesktop(): any {
    return this.deviceService.isDesktop();
  }

  isTablet(): any {
    return this.deviceService.isTablet();
  }

  openSnackBar(message: string, duration: number, status: string): void {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration,
      panelClass: status,
      data: {
        message,
        status
      }
    });
  }

  openConfirmDialog(_data: Partial<IConfirmDialogConfig>, config?: MatDialogConfig) {
    return this.dialog.open<ConfirmDialogComponent, IConfirmDialogConfig>(ConfirmDialogComponent, {
      width: '510px',
      disableClose: true,
      autoFocus: false,
      data: { newStyle: true, api: 'close', class: 'primary', ..._data },
      ...config
    });
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle('Envoy | ' + newTitle);
  }

  /**
   * Download a blob/text file from the servers without redirecting to another page
   * @param binaryData
   * @param fileType
   * @param fileName
   */
  downloadFile(binaryData: BlobPart, fileType: string, fileName: string) {
    // transform data to blob and create url
    const file = new Blob([binaryData], { type: fileType });
    const url = window.URL.createObjectURL(file);
    // Create Anchor element
    const anchorEl = document.createElement('a');
    anchorEl.download = fileName;
    anchorEl.href = url;

    //   dispatch click event on the anchor element
    anchorEl.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
    window.URL.revokeObjectURL(url);
  }

  clearDate(date: any): string {
    if (date != "") {
      const sent_date = new Date(date);
      const momentDate = new MomentDateAdapter('en');
      const dates = momentDate.createDate(
        sent_date.getFullYear(),
        sent_date.getUTCMonth(),
        sent_date.getDate()
      );
      return momentDate.format(dates, 'L');
    }
    return ""
  }

  clearTime(date: any): string {
    if (date != "") {
      const sent_date = new Date(date);
      let hours = sent_date.getHours();
      let minutes: any = sent_date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes + ' ' + ampm;
    }
    return ""
  }

  checkDate(userDate: string) {
    const ToDate = new Date();
    if (new Date(userDate).getTime() <= ToDate.getTime()) {
      return 'expired';
    }
    if (new Date(userDate).getTime() <= (ToDate.getTime() + 7 * 24 * 60 * 60 * 1000)) {
      return 'nearly';
    }
    return 'valid';
  }

  isNewDate(date: string) {
    const now = new Date();
    const _date = new Date(date);
    return _date >= now;
  }

  clear_interval() {
    if (this.handleAll) {
      clearInterval(this.handleAll);
    }
    if (this.handle) {
      clearInterval(this.handle);
    }
  }

  /**
   * parse jwt encoded value to get details
   * @param token
   */
  private decodeToken(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
}
