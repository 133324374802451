<mat-toolbar color="accent">
    <a role="img" [routerLink]="utils.isDesktop() ? '/reservations/live' : '/reservations'" class="envoy-logo">
        <img src="assets/logo.svg" height="28" alt="Envoy Logo"/>
    </a>
    <span class="flex-grow-1"></span>
    <div class="nav-menu lg:flex">
        @for (item of menuItems; track item.route) {
            <a class="nav-item" mat-button [routerLink]="item.route" routerLinkActive="nav-item__active"
               #rlItem="routerLinkActive" [routerLinkActiveOptions]="item.options ?? {exact: false}"
               *appHasPermission="item.permission">
                <mat-icon [svgIcon]="!rlItem.isActive ? item.icon : item.icon + '_open'"/>
                {{ item.title }}
            </a>
        }
    </div>
    <span class="flex-grow-1"></span>
    <div class="actions-center lg:flex">
        <a mat-button class="action-item" aria-label="Drivers Messaging" *appHasPermission="'admin/drivers'"
           routerLink="/chat" routerLinkActive="action-item__active" #rl="routerLinkActive">
            <mat-icon [svgIcon]="rl.isActive ? 'sms_open_icon' : 'sms_icon'"
                      [matBadgeHidden]="!(chatService.unreadChat$ | async)"
                      matBadgeColor="warn" matBadge="&#8288;"
            />
            Messages
        </a>
        <button mat-button class="action-item" *appHasPermission="[]"
                [class.action-item__active]="menuBtn.menuOpen" aria-label="Notifications center"
                [matMenuTriggerFor]="notificationMenu" #menuBtn="matMenuTrigger"
                (menuClosed)="handleNotificationMenuState(false)"
                (menuOpened)="handleNotificationMenuState(true)">
            <mat-icon svgIcon="notification_icon{{menuBtn.menuOpen ? '_open' : ''}}"
                      [matBadgeHidden]="!(notification.unreadNotification$ | async)"
                      matBadgeColor="warn" matBadge="&#8288;"
            />
            Notifications
        </button>
        <mat-divider *appHasPermission="'admin/drivers'" class="align-self-stretch" vertical/>
        <button mat-button class="action-item mr-2" [class.action-item__active]="systemSearchOpened"
                aria-label="Search system" (click)="openSearchDialog()">
            <mat-icon svgIcon="search_normal_icon{{systemSearchOpened ? '_open' : ''}}"/>
            System search
        </button>
    </div>
    <!--  Show mobile burger menu on medium and small screens  -->
    <button mat-icon-button class="lg:hidden" [matMenuTriggerFor]="mobileMenu">
        <mat-icon>menu</mat-icon>
    </button>
    @if (auth.userData | async) {
        <button class="capitalize" mat-button [matMenuTriggerFor]="profile">
            {{ (auth.userData | async)?.first_name }}
        </button>
        <mat-menu class="profile-mat-menu" #profile="matMenu">
            <button class="logout-button" (click)="logout()" mat-menu-item>
                <mat-icon>logout</mat-icon>
                Logout
            </button>
        </mat-menu>
    }
    <div id="playSound"></div>
</mat-toolbar>

<!-- Notification Menu -->
<mat-menu class="notification-menu" yPosition="below" #notificationMenu="matMenu">
    <div class="notification-menu__header">
        <mat-icon svgIcon="notification_icon_open"></mat-icon>
        <span>Notifications</span>
    </div>
    <div class="notification-menu__content" envoyInfiniteScroller [scrollPercent]="80" [pageSize]="10"
         [length]="notification.totalCount" [(loading)]="viewInfinityLoader"
         (scrollInfinite)="notification.triggerNotificationLoad($event.page)">
        @for (item of notificationData; track item.id; let last = $last) {
            <button (click)="notification.readNotification(item)" [class.read]="item.read" mat-menu-item>
                <mat-icon [svgIcon]="item.key"/>
                <div class="notification-title">
                    <h3>{{ item.subject }}</h3>
                    <span class="text-secondary">{{ item.created | dayDate: 'M/d/Y. H:MM' }}</span>
                </div>
                <p class="content" [innerHTML]="item.body"></p>
            </button>
            <mat-divider class="mb-1" *ngIf="!last"/>
        } @empty {
            <div class="no-notifications">
                <mat-icon svgIcon="no-notification"/>
                <h2 class="text-center">No Notifications Available</h2>
            </div>
        }
        @if (viewInfinityLoader) {
            <div class="flex center my-2">
                <mat-spinner mode="indeterminate" diameter="30"/>
            </div>
        }
    </div>
</mat-menu>

<!-- Burger Mobile Menu -->
<mat-menu class="mobile-nav-menu" #mobileMenu="matMenu">
    @for (item of menuItems; track item.route) {
        <a class="nav-item" mat-menu-item [routerLink]="item.route" routerLinkActive="active-item"
           #rlItem="routerLinkActive" *appHasPermission="item.permission">
            <mat-icon [svgIcon]="!rlItem.isActive ? item.icon : item.icon + '_open'"/>
            {{ item.title }}
        </a>
    }
</mat-menu>
