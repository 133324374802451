export const environment = {
  production: true,
  api_url: 'https://driver-api.envoyamerica.com/',
  image_url: 'https://driver-api.envoyamerica.com/',
  main_websitelink: 'dashboard.envoyamerica.com/',
  cookie_https: false,
  cookie_domain: '.envoyamerica.com',
  page_size: 20,
  protocol: 'https://',
  firestore_chat: 'chats',
  firestore_reservations: 'reservations_realtime_updates_envoy_prod',
  firebase: {
    apiKey: "AIzaSyBPHgXmmLECRjLsfLXbTBGfBrIkxCeoMOM",
    authDomain: "genial-smoke-393922.firebaseapp.com",
    projectId: "genial-smoke-393922",
    storageBucket: "genial-smoke-393922.appspot.com",
    messagingSenderId: "427998001475",
    appId: "1:427998001475:web:ba9ba437edc70a9ca117f6",
    measurementId: "G-HPPXHYY7V6"
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZW52b3lhbWVyaWNhMjAyMyIsImEiOiJjbGd1aXl3OGowbTE5M21vNW9wb2Fld2Y4In0.Iih0t247hvEPX1KJ1srunQ'
  },
  ws_server:"wss://driver-track.envoyamerica.com/ws/reservations/live",
  stripe_public_key: "pk_live_51PLEosI2igxIr5kLxg2c1DkrSwWJgXdbN3MMajKECRQDy1Iq05wUAbYjvfLhjnFGKn4WtE3xx27bDtZks3gxL0jL00kW8H3yIX"
};
